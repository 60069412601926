import { useNavigation } from '@remix-run/react';
import { Loader } from 'lucide-react';
import { useIsSubmitting } from '~/hooks/useIsSubmitting';
import { Button, ButtonProps } from './ui/button';

type FormSubmitButtonProps = ButtonProps & {
  className?: string;
  label: string | React.ReactNode;
  onClick?: () => void;
  variant?: string;
};

export function FormSubmitButton(props: FormSubmitButtonProps) {
  const { className, label, onClick, variant = 'default', disabled, ...rest } = props;
  const isSubmitting = useIsSubmitting();
  const navigate = useNavigation();
  const isLoading = navigate.state === 'loading';

  return (
    <Button
      className={className}
      onClick={onClick}
      type='submit'
      variant={variant}
      disabled={isSubmitting || isLoading || disabled}
      {...rest}
    >
      {isSubmitting && <Loader className='w-4 h-4 mr-2 animate-spin' />}
      {label}
    </Button>
  );
}
